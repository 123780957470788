import React from 'react'

function Header({ greeting, text1, text2 }) {
    return (
        <div className="top">
            <div className="greeting">{greeting}</div>
            <div className="msg">{text1}</div>
            <div className="msg">{text2}</div>
            <div className="buttons">
                <a href="https://docogen.com/newsletter" target="_blank" rel="noreferrer" className="btn p">Subscribe</a>
                <a href="https://doco.link/discord" target="_blank" rel="noreferrer" className="btn s">Join DoCoNation</a>
            </div>
        </div>
    )
}

export default Header
