import React from 'react'
import db from '../firebase'

export default function Blogs({ title, url, clickCount, id }) {
    const clickCounter = () => {
        db.collection('blogs').doc(id).set({
            clicks: clickCount + 1
        }, { merge: true })
    }
    return (

        <a href={url} target="_blank" rel="noreferrer" className="link">
            <div onClick={clickCounter} className="txt">{title}</div>
        </a>

    )
}
