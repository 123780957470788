import React from 'react'

function TopLink({ bg, url, title }) {
  let topLinkStyle = {
    background: `url(${bg}) #222222`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'scroll',
  }
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="link b"
      style={topLinkStyle}
    >
      <div className="txt">{title}</div>
    </a>
  )
}

export default TopLink
