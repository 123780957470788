import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyB4XnjA_8jE3wlAXgjsSsb2NXQfREmRYkA",
    authDomain: "doco-link.firebaseapp.com",
    projectId: "doco-link",
    storageBucket: "doco-link.appspot.com",
    messagingSenderId: "41436812569",
    appId: "1:41436812569:web:dbaaa27fe7908f90b36188",
    measurementId: "G-ZP9PGDH95T"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()

export default db