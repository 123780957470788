import React from 'react'
import Facebook from '../socials/fb.svg'
import Pinterest from '../socials/pinterest.svg'
import Linkedin from '../socials/linkedin.svg'
import Insta from '../socials/insta.svg'
import Twitter from '../socials/twitter.svg'
import YouTube from '../socials/YouTube.svg'

function Socials() {
    return (
        <div className="socials">
            <a href="https://www.instagram.com/docogen/" rel="noreferrer" target="_blank" className="icon"><img src={Insta}
                alt="Instagram" /></a>
            <a href="https://www.facebook.com/DotComGenerations/" rel="noreferrer" target="_blank" className="icon"><img src={Facebook}
                alt="Facebook" /></a>
            <a href="https://twitter.com/_DoCoGen" target="_blank" rel="noreferrer" className="icon"><img src={Twitter}
                alt="Twitter" /></a>
            <a href="https://www.linkedin.com/company/docogen" rel="noreferrer" target="_blank" className="icon"><img
                src={Linkedin} alt="LinkedIn" /></a>
            <a href="https://in.pinterest.com/dotcomgenerations/" rel="noreferrer" target="_blank" className="icon"><img
                src={Pinterest} alt="Pinterest" /></a>
            <a href="https://doco.link/youtube" rel="noreferrer" target="_blank" className="icon"><img
                src={YouTube} alt="YouTube" /></a>
        </div>
    )
}

export default Socials
