import React from 'react'

const Videos = ({ name, date, yt, img, cl }) => {
    return (
        <div className='video'>
            <a className="card" href={yt} rel='noreferrer' target='_blank'>
                <div className="img">
                    <img src={img} alt={name} />
                </div>
                <div className="text">
                    <div className={"name " + cl}>
                        {name}
                    </div>

                    <br />
                    <div className="date">
                        {date}
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Videos
