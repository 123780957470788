import React from 'react'

const Event = ({ linkActive, img, meetingLink, name, date, time }) => {
    return (
        <div className='event'>
            <div className='card'>
                <div className="img">
                    <img src={img} alt={name} />
                </div>
                <div className="text">
                    {/* 
                        <div className="name">
                        {name}
                    </div>
                */}
                    <div className="info">
                        <span className='date'>{date}</span>
                        <span className='time'>{time}</span>
                    </div>
                </div>
                <div className="btnHolder">
                    {linkActive
                        ?
                        (
                            <a
                                href={meetingLink}
                                target="_blank"
                                rel="noreferrer"
                                className="btn p"
                            >
                                Join Session
                            </a>
                        )
                        :
                        (
                            <a
                                href="https://doco.link/nation-whatsapp"
                                className="btn p"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Get Notified
                            </a>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Event
