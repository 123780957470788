import React, { useEffect, useState } from 'react'
import Header from './components/Header'
// import Footer from './components/Footer'
import Socials from './components/Socials'
import TopLink from './components/TopLink';
import './App.css';
import db from './firebase'
import Blogs from './components/Blogs';
import Event from './components/Event';
import Videos from './components/Videos';

function App() {
  const [header, setHeader] = useState({})
  const [topLinks, setTopLinks] = useState([])
  const [blogs, setBlogs] = useState([])
  const [events, setEvents] = useState([])
  const [videos, setVideos] = useState([])


  useEffect(() => {
    db.collection('header').doc('0ysiuArlhngZ01xEq4rQ').onSnapshot((doc) => {
      setHeader(doc.data())
    })
    db.collection('topLinks').orderBy('priority', 'asc').onSnapshot((snapshot) => {
      setTopLinks(snapshot.docs.map((doc) => (doc.data())))
    })
    db.collection('blogs').orderBy('priority', 'desc').limit(3).onSnapshot((snapshot) => {
      setBlogs(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })))
    })
    db.collection('events').orderBy('priority', 'desc').limit(3).onSnapshot((snapshot) => {
      setEvents(snapshot.docs.map((doc) => (doc.data())))
    })
    db.collection('videos').orderBy('num', 'desc').limit(3).onSnapshot((snapshot) => {
      setVideos(snapshot.docs.map((doc) => (doc.data())))
    })
  }, [])

  return (
    <div className="App">

      {/* Header */}
      <Header
        greeting={header.greeting}
        text1={header.text1}
        text2={header.text2}
      />

      {/* Socials */}
      <Socials />
      <div className="scroll">

        {/* Live Events */}
        {(events.length === 0) ?
          (
            <div></div>
          ) : (
            <div className="section">
              <div className="head">Upcoming<br />Live Events 🌟</div>
              <div className="events">
                {events.map(({ name, img, meetingLink, active, date, time }) => (
                  <Event
                    img={img}
                    linkActive={active}
                    meetingLink={meetingLink}
                    name={name}
                    date={date}
                    time={time}
                  />
                ))}
              </div>
            </div>
          )
        }


        <div className="spacer"></div>

        {/* Top Links */}
        <div className="section">
          <div className="head">Top Links 🔗</div>
          {topLinks.map(({ bg, url, title }) => (
            <TopLink
              bg={bg}
              title={title}
              url={url}
            />
          ))}
        </div>

        <div className="spacer"></div>

        {/* Blogs */}
        {(blogs.length === 0)
          ?
          (
            <div></div>
          )
          :
          (
            <div className="section">
              <div className="head">Blogs 📝</div>
              {blogs.map((blog) => (
                <Blogs
                  title={blog.data.title}
                  url={blog.data.url}
                  clickCount={blog.data.clicks}
                  id={blog.id}
                />
              ))}
              <Blogs
                title='See More...'
                url='https://blog.docogen.com'
              />
            </div>
          )
        }

        <div className="spacer"></div>

        {/* Recorded Events */}
        {(videos.length === 0)
          ?
          (
            <div></div>
          )
          :
          (
            <div className="section">
              <div className="head">Recent Session Recordings 💻</div>
              <div className="videos">
                {videos.map(({ name, date, yt, img }) => (
                  <Videos date={date} img={img} name={name} yt={yt} />
                ))}
                <Videos img='https://i.ibb.co/CVFsvvT/Watch-More.png' name='Watch More' yt='https://doco.link/youtube' cl='c' />
              </div>
            </div>

          )}




        {/* Webinars 
        <div className="section">
          <div className="head">Webinars 💻</div>
          <a href="#" target="_blank" rel="noreferrer" className="link">
            <div className="txt">Link</div>
          </a>
          <a href="#" target="_blank" rel="noreferrer" className="link">
            <div className="txt">Link</div>
          </a>
        </div>

        <div className="spacer"></div>*/}


        {/* Links 
        <div className="section">
          <div className="head">Useful Links 🌐</div>
          <a href="#" target="_blank" rel="noreferrer" className="link">
            <div className="txt">Link</div>
          </a>
          <a href="#" target="_blank" rel="noreferrer" className="link">
            <div className="txt">Link</div>
          </a>
        </div>
      
      */}
        {/*<Footer />*/}
      </div>
    </div>
  );
}

export default App;
